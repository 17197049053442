import axios from "axios";
// export const baseURL = `http://192.168.1.2:3000/v1`;
// export const baseURLIO = `http://192.168.1.2:3000`;

//  export const baseURL = `http://43.204.45.193/v1`; //demo server
//   export const baseURLIO = `http://43.204.45.193`; //demo server

export const baseURL = `https://restaurantdemo.ebeaconsofts.com/v1`;//For Tahzeeb Build
export const baseURLIO = `https://restaurantdemo.ebeaconsofts.com`;//For Tahzeeb Build
export const headers = {
  "Content-Type": "application/json",
  //'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};
const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  responseType: "json",
});

function createAxiosResponseInterceptor(axiosInstance) {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      axiosInstance.interceptors.response.eject(interceptor);
      window.localStorage.clear();
      window.location.replace("/login");
      return Promise.reject(error);
    }
  );
}

createAxiosResponseInterceptor(instance);

export default instance;
